'use strict';

angular.module('gokibitz.services')
.factory('Auth', function Auth($http, $location, $rootScope, Session, User, $cookieStore) {
	$rootScope.currentUser = $cookieStore.get('user') || null;
	$cookieStore.remove('user');

	return {

		login: function (provider, user, callback) {
			var cb = callback || angular.noop;
			Session.save({
				provider: provider,
				email: user.email,
				password: user.password,
				rememberMe: user.rememberMe
			}, function (user) {
				//console.log('save user', user);
				$rootScope.currentUser = user;
				return cb();
			}, function (err) {
				return cb(err.data);
			});
		},

		logout: function (callback) {
			var cb = callback || angular.noop;
			Session.delete(function (res) {
				$rootScope.currentUser = null;
				return cb();
			},
			function (err) {
				return cb(err.data);
			});
		},

		createUser: function (userinfo, callback) {
			var cb = callback || angular.noop;
			User.save(userinfo, function (user) {
				$rootScope.currentUser = user;
				return cb();
			},
			function (err) {
				return cb(err.data);
			});
		},

		currentUser: function () {
			Session.get(function (user) {
				//console.log('user', user);
				$rootScope.currentUser = user;
			});
		},

		changePassword: function (email, oldPassword, newPassword, callback) {
			var cb = callback || angular.noop;
			User.update({
				email: email,
				oldPassword: oldPassword,
				newPassword: newPassword
			}, function (user) {
				return cb();
			}, function (err) {
				return cb(err.data);
			});
		},

		requestPasswordReset: function (email, callback) {
			var cb = callback || angular.noop;
			$http.get(`/auth/reset-password/${email}`)
				.then(result => {
					cb(result);
				}, err => {
					cb(err);
				});
		},

		resetPassword: function (username, token, newPassword, callback) {
			var cb = callback || angular.noop;
			$http.post(`/auth/reset-password/${username}`, { token, newPassword })
				.then(result => {
					cb(result);
				}, err => {
					cb(err);
				});
		},

		removeUser: function (email, password, callback) {
			var cb = callback || angular.noop;
			User.delete({
				email: email,
				password: password
			}, function (user) {
				//console.log(user + 'removed');
				return cb();
			}, function (err) {
				return cb(err.data);
			});
		}
	};
});
